<template>
  <component
    :is="componentTypeOptions.component"
    :target="target"
    :[componentTypeOptions.attribute]="link"
    class="card card__padding"
    :style="[cardPadding, cardCorners, backgroundColor]"
    :class="[
      cardShadowClass,
      cardBorderClass,
      cardFullHeightClass,
      cardFullWidthClass,
      cardDisabled,
    ]"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { ComponentType, ResponsiveSpacingNumbers } from '@/types'

interface Props {
  componentType?: ComponentType | any
  target?: '_blank' | '_self'
  link?: string
  background?: string
  shadow?: '' | 'shadow-flat-soft' | 'shadow-small-soft' | 'shadow-large-soft'
  border?: 'light-gray-border' | ''
  options?: ('' | 'is_full_width' | 'is_full_height' | 'hide_bottom_margin')[]
  padding?: ResponsiveSpacingNumbers | string
  borderRadius?: number | string
  roundedCorners?: (
    | ''
    | 'TopLeft'
    | 'TopRight'
    | 'BottomLeft'
    | 'BottomRight'
  )[]
  isDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  componentType: 'div',
  link: undefined,
  background: '#fff',
  target: undefined,
  shadow: '',
  border: undefined,
  options: undefined,
  padding: undefined,
  borderRadius: 16,
  roundedCorners: () => ['TopLeft', 'TopRight', 'BottomLeft', 'BottomRight'],
  isDisabled: false,
})

const componentTypeOptions = computed(() => {
  return props.componentType === 'div'
    ? { component: 'div', attribute: '' }
    : {
        component: resolveComponent('BaseLink'),
        attribute: 'to',
      }
})

const backgroundColor = computed(() => {
  return {
    backgroundColor: props.background,
  }
})

const cardShadowClass = computed(() => {
  return props.shadow
})

const cardDisabled = computed(() => props.isDisabled && 'card--disabled')

const cardBorderClass = computed(() => {
  return (props.border && `card--${props.border}`) || ''
})

const cardPadding = computed(() => {
  return {
    '--card-padding-mobile': `${props.padding?.mobile || 0}px`,
    '--card-padding-tablet': `${props.padding?.tablet || 0}px`,
    '--card-padding-desktop': `${props.padding?.desktop || 0}px`,
  }
})

const cardFullHeightClass = computed(() => {
  return props.options?.includes('is_full_height') ? 'card__full-height' : ''
})
const cardFullWidthClass = computed(() => {
  return props.options?.includes('is_full_width') ? 'card__full-width' : ''
})

const cardCorners = computed(() => {
  const optionsArray =
    props.roundedCorners &&
    props.roundedCorners.map((corner) => `Border${corner}Radius`)
  return optionsArray?.reduce(
    (o, key) => ({
      ...o,
      [key]: `${props.borderRadius || 16}px`,
    }),
    {},
  )
})
</script>

<style lang="scss" scoped>
.card {
  --card-padding-mobile: 0;
  --card-padding-tablet: 0;
  --card-padding-desktop: 0;

  color: inherit;
  display: flex;
  overflow: hidden;
  height: auto;
  width: fit-content;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }

  &__padding {
    padding: var(--card-padding-mobile);

    @media (min-width: $breakpoint-md) {
      padding: var(--card-padding-tablet);
    }

    @media (min-width: $breakpoint-lg) {
      padding: var(--card-padding-desktop);
    }
  }

  &__hide-bottom-margin {
    margin-bottom: 0;
  }

  &--light-gray-border {
    @include card-gray-border;
  }

  &--navy-700-border {
    @include card-navy-border;
  }

  &__full-height {
    height: 100%;
  }

  &__full-width {
    width: 100%;
  }

  &--disabled {
    opacity: 0.38;
    cursor: not-allowed;
  }
}

a.card:not(.card--disabled):hover {
  --hover-color: var(--color-link);
}
</style>
